import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./assets/fonts/GothamRnd-Medium.woff2";
import "./assets/fonts/GothamRnd-Book.woff2";
import "./index.scss";
import { CircularProgress, CssBaseline } from "@material-ui/core";
import * as serviceWorker from "./serviceWorker";
import ThemeProvider from "./features/common/themes/ThemProvider";
import ConfigGenerator from "./features/common/utils/configGenerator";
import axios from "axios";
import { Configuration } from "./features/common/utils/configuration";

function constructConfigObject(configs: any) {
  ConfigGenerator.BuildConfigurations(configs);
}

axios
  .get("/getconfig/")
  .then((configRes: { data: any }) => {
    constructConfigObject(configRes.data);
    renderApp();
  })
  .catch((err: any) => {
    console.log(err);
  });

console.log("isAuthLoaded ind", window.location.pathname);
function renderApp() {
  console.log(process.env.REACT_APP_CONSOLE_ENABLED, "2225511415");
  //  if (Configuration.console_enabled !== 'true') {
  if (process.env.REACT_APP_CONSOLE_ENABLED !== "true") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }
  console.log("isAuthLoaded index", window.location.pathname);
  const AppComponent = React.lazy(() => import("./AppComponent"));
  ReactDOM.render(
    <ThemeProvider>
      <CssBaseline />
      <Suspense
        fallback={
          <div className="loaderStyles">
            <CircularProgress color="secondary" />
          </div>
        }
      >
        <AppComponent />
      </Suspense>
    </ThemeProvider>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
