import { Theme } from "@material-ui/core";
import { defaultTheme } from "./defaultTheme";

export function themeCreator(theme: string): Theme {
  return themeMap[theme];
}

const themeMap: { [key: string]: Theme } = {
  darkTheme: defaultTheme
};
